import React from 'react';
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";

import Main from './components/Main';


const mainTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2d2d2d",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#19a4c9",
            contrastText: "#ffffff"
        }
    },
    typography: {
        useNextVariants: true
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1em",
                color: "white",
                backgroundColor: "#2d2d2d"
            }
        }
    }
});

const styles = theme => ({
    appWrapper: {
        position: "relative",
        display: "flex",
        width: "100%",
        minHeight: "100vh",
        backgroundColor: '#FF0000' //TODO: Remove After Dev
    }
});

class App extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className={classes.appWrapper}>
                    <CssBaseline />
                    <section>
                        <Main />
                    </section>
                </div>
            </MuiThemeProvider>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(App);
