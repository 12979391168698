import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import { width, height, lineHeight } from "@material-ui/system";

const styles = theme => ({
  backgroundImg: {
        backgroundImage:
          "linear-gradient(to bottom, rgba(57, 91, 142, 0.7), rgba(37, 69, 118, 0.93)), url(/Images/background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh"
    },
  nameText: {
        color: 'white',
        fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif;",
        fontSize: '3.75em',
        fontWeight: '900',
        letterSpacing: '0.1em',
        lineHeight: '1em',
        textTransform: 'uppercase',
        textAlign: 'center'
  },
  nameLine: {
        display: 'block',
        width: '320px',
        height: '3px',
        background: '#5b9bbc',
        marginLeft: 'auto',
        marginRight: 'auto'
  },
  nameSubText: {
      color: 'white',
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif;",
      fontSize: '1.875em',
      fontWeight: '300',
      letterSpacing: '0.1em',
      lineHeight: '1em',
      textTransform: 'uppercase',
      textAlign: 'center'
  }
});

class Main extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.backgroundImg} direction="row">
        <Grid container style={{ width: "100vw", height: "92vh" }} justify="center" alignItems="center">
          <Grid item>
            <h1 className={classes.nameText}>Jean-Claude Abela</h1>
            <div style={{marginBottom: '5px'}} className={classes.nameLine}></div>
            <h4 className={classes.nameSubText}>Software Engineer</h4>
          </Grid>
        </Grid>
        <Grid container style={{ width: "100vw", height: "8vh" }} justify="center" alignItems="center">
          <Grid item>
            <p>About Me</p>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default connect()(withStyles(styles)(Main));
